/**
 *
 * @author Ivo Danic Garrido
 * @company  Erillam Healthcare
 * @version 2.0
 */

 const AppConfiguration = {
    WEB_SERVICE_HOST:process.env.NODE_ENV === "production" ? window.location.hostname : "localhost",
    WEB_SERVICE_PORT: process.env.NODE_ENV === "production" ? ":8080" : ":8080",
    WEB_SERVICE_PROTOCOL:process.env.NODE_ENV === "production" ? "https://" : "http://",
    WEB_SERVICE_UNSECURE_PATH: process.env.NODE_ENV === "production" ? "/whmws/app/" : "/whmwsdev/app/",
    WEB_SERVICE_SECURE_PATH:  process.env.NODE_ENV === "production" ? "/whmws/app/sc/" : "/whmwsdev/app/sc/",
};

export const SECURE_ENDPOINT = AppConfiguration.WEB_SERVICE_PROTOCOL.concat(
    AppConfiguration.WEB_SERVICE_HOST,
    AppConfiguration.WEB_SERVICE_PORT,
    AppConfiguration.WEB_SERVICE_SECURE_PATH
);
export const UNSECURE_ENDPOINT = AppConfiguration.WEB_SERVICE_PROTOCOL.concat(
    AppConfiguration.WEB_SERVICE_HOST,
    AppConfiguration.WEB_SERVICE_PORT,
    AppConfiguration.WEB_SERVICE_UNSECURE_PATH
);

/* Apartado de las encuestas  */

const AppConfigurationQuiz = {
    WEB_SERVICE_HOST:  process.env.NODE_ENV === 'production' ? window.location.hostname : "192.168.1.100",
    WEB_SERVICE_PORT: process.env.NODE_ENV === 'production' ? ":8080" : ":8080",
    WEB_SERVICE_PROTOCOL: process.env.NODE_ENV === 'production'? "https://" : "http://",
    WEB_SERVICE_UNSECURE_PATH: process.env.NODE_ENV === 'production' ? "/survey/app/" :  "/survey/app/" ,
    WEB_SERVICE_SECURE_PATH: process.env.NODE_ENV === 'production' ? "/survey/app/sc/" :  "/survey/app/sc/" ,
};

export const SECURE_ENDPOINT_QUIZ = AppConfigurationQuiz.WEB_SERVICE_PROTOCOL.concat(
    AppConfigurationQuiz.WEB_SERVICE_HOST,
    AppConfigurationQuiz.WEB_SERVICE_PORT,
    AppConfigurationQuiz.WEB_SERVICE_SECURE_PATH
);
export const UNSECURE_ENDPOINT_QUIZ = AppConfigurationQuiz.WEB_SERVICE_PROTOCOL.concat(
    AppConfigurationQuiz.WEB_SERVICE_HOST,
    AppConfigurationQuiz.WEB_SERVICE_PORT,
    AppConfigurationQuiz.WEB_SERVICE_UNSECURE_PATH
);

/* Apartado de los reportes  */

const AppConfigurationReports = {
    WEB_SERVICE_HOST:process.env.NODE_ENV === "production" ? window.location.hostname : "192.168.1.100",
    WEB_SERVICE_PORT: process.env.NODE_ENV === "production" ? ":8080" : ":8080",
    WEB_SERVICE_PROTOCOL:process.env.NODE_ENV === "production" ? "https://" : "http://",
    WEB_SERVICE_UNSECURE_PATH: process.env.NODE_ENV === "production" ? "/wms-reports/app/" : "/wms-reports-dev/app/",
    WEB_SERVICE_SECURE_PATH:  process.env.NODE_ENV === "production" ? "/wms-reports/app/sc/" : "/wms-reports-dev/app/sc/",
};

export const SECURE_ENDPOINT_REPORTS = AppConfigurationReports.WEB_SERVICE_PROTOCOL.concat(
    AppConfigurationReports.WEB_SERVICE_HOST,
    AppConfigurationReports.WEB_SERVICE_PORT,
    AppConfigurationReports.WEB_SERVICE_SECURE_PATH
);
export const UNSECURE_ENDPOINT_REPORTS = AppConfigurationReports.WEB_SERVICE_PROTOCOL.concat(
    AppConfigurationReports.WEB_SERVICE_HOST,
    AppConfigurationReports.WEB_SERVICE_PORT,
    AppConfigurationReports.WEB_SERVICE_UNSECURE_PATH
);